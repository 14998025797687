.lunch-card {
    padding: 10px;
}

.lunch-range {
    padding: 4px;
    --knob-size: 15px;
    --knob-background: #ff3399;
    --bar-background: #ecd1e1;
    --bar-background-active: #ff3399;
}

.lunch-header {
    color: #f4f5f8;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    display: contents;
    font-size: 30px;
}

.lunch-toolbar {
    padding-top: 10px;
    --min-height: 60px;
    --background: #222428;
    align-items: center;
    text-align: center;
    vertical-align: middle;
}

.lunch-button {
    --background: #ff3399;
    --background-activated: #7a2353;
}

.lunch-refresh {
    float: right;
    margin-right: 10px;
}

.lunch-score {
    text-align: right;
}

.lunch-header-row {
    align-items: center;
    text-align: center;
    vertical-align: middle;
}

.lunch-toggle {
    --background-checked: #ff3399;
}

.lunch-result-card {
    overflow: scroll;
}

.lunch-result-title {
    text-align: center;
    font-weight: bold;
    font-size: 40px;
    margin-top: 0;
}

.lunch-result-header {
    padding-top: 10px;
}

.lunch-gif {
    height: 300px;
    margin: 0;
    padding: 0;
}

.lunch-footer {
    background-color: #f4f5f8;
}

